html,
body {
  background: #ee9ca7;
}

#cake {
  display: block;
  position: relative;
  margin: -10em auto 0 auto;
}
.middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ============================================== Candle
*/

.candles {
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 228px;
  left: 50%;
  margin-left: -2.4px;
  margin-top: -8.33333333px;
  width: 5px;
  height: 35px;
  transform: translateY(-300px);
  backface-visibility: hidden;
  animation: in 500ms 6s ease-out forwards;
}
.candles:after,
.candles:before {
  background: rgba(255, 0, 0, 0.4);
  content: "";
  position: absolute;
  width: 100%;
  height: 2.22222222px;
}
.candles:after {
  top: 25%;
  left: 0;
}
.candles:before {
  top: 45%;
  left: 0;
}

/* ============================================== Fire
*/

.fire {
  border-radius: 100%;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -2.6px;
  width: 6.66666667px;
  height: 18px;
}
.fire:nth-child(1) {
  animation: fuego 2s 6.5s infinite;
}
.fire:nth-child(2) {
  animation: fuego 1.5s 6.5s infinite;
}
.fire:nth-child(3) {
  animation: fuego 1s 6.5s infinite;
}
.fire:nth-child(4) {
  animation: fuego 0.5s 6.5s infinite;
}
.fire:nth-child(5) {
  animation: fuego 0.2s 6.5s infinite;
}

/* ============================================== Animation Fire
*/

@keyframes fuego {
  0%,
  100% {
    background: rgba(254, 248, 97, 0.5);
    box-shadow: 0 0 40px 10px rgba(248, 233, 209, 0.2);
    transform: translateY(0) scale(1);
  }
  50% {
    background: rgba(255, 50, 0, 0.1);
    box-shadow: 0 0 40px 20px rgba(248, 233, 209, 0.2);
    transform: translateY(-20px) scale(0);
  }
}

@keyframes in {
  to {
    transform: translateY(0);
  }
}

.text {
  color: #8b6a60;
  font-weight: 300;
  font-style: italic;
  text-align: center;
}
