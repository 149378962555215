@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap");

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: "Heebo", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

.heeboFont {
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.slick-slide > div {
  margin: 0 3px;
}
